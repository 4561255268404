//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import AdminViewModal from '@/modules/admins/components/admin-view-modal.vue';
import AdminFormModal from '@/modules/admins/components/admin-form-modal.vue';
const { fields } = UserModel;

export default {
  name: 'app-admins-list-table',
    components: {
    [AdminViewModal.name]: AdminViewModal,
    [AdminFormModal.name]: AdminFormModal
  },
    props: {
    
  },

  data() {
    return {
      record: null,
      createAdminDialogVisible:false ,
      createAdminModalVisible: false,
      viewAdminDialogVisible:false ,
      viewAdminModalVisible: false,
      deleteAdminDialogVisible:false ,
      deleteAdminModalVisible: false,
      modalSuccessVisible: false,


      selectedRow: null,
      selectedId: null,
      dialogType: null,
      modalVisible: false,
      dialogVisible: false,
      pagination: {
        page: 1,
        rowsPerPage: 10 
      },
      page: 1,
      columns: [
        {
          name: 'fullName',
          align: 'center',
          label: i18n('tables.admin.fullName'),
          field: 'fullName',
        },              
         {
          name: 'phoneNumber',
          align: 'center',
          label: i18n('tables.admin.phoneNumber'),
          field: 'phoneNumber',
        },{
          name: 'email',
          align: 'center',
          label: i18n('tables.admin.emailAddress'),
          field: 'email',
        }, 
        {
          name: 'branch',
          align: 'center',
          label: i18n('tables.admin.branch'),
          field: 'branch',
        },
        {
          name: 'status',
          align: 'center',
          label: i18n('tables.admin.status'),
          field: 'status',
        },
        {
          name: 'action',
          align: 'center',
          label: i18n('tables.admin.action'),
          field: 'action',
        },



      ],
    };
  },

  computed: {
    ...mapGetters({
    //   rows: 'tourGuide/list/rows',
    //   count: 'tourGuide/list/count',
    //   loading: 'tourGuide/list/loading',
    //   pagination: 'tourGuide/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'admins/list/rows',
      disablePrevious: 'admins/list/disablePrevious',
      disableNext: 'admins/list/disableNext',
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'admins/list/loading',
      isFirstPage: 'admins/list/isFirstPage',
      isLastPage: 'admins/list/isLastPage'
    }),

    fields() {
      return fields;
    },
    getItemsPerPage() {
          // const { screen } = this.$q;
          //   if (screen.lt.sm) {
          //       return 4;
          //   }
          //   if (screen.lt.md) {
          //       return 6;
          //   }
          //   if (screen.lt.lg) {
          //       return 9;
          //   }
          //   if (screen.lt.xl) {
          //       return 9;
          //   }
            return 10;
        },

        isRTL(){
          return i18n.locale == 'ar'
        }
  },
  async mounted() {
    // console.log(this.rows);
    const pagination = {
      sortBy: "desc",
      limit: this.getItemsPerPage
    }
    await this.doFetch({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})
    // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})
    // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})    
    this.finishLoading()
  },

  methods: {
    ...mapActions({
      doFetch: 'admins/list/doFetch',
      setDisableNext: 'admins/list/setDisableNext',
      setDisablePrevious: 'admins/list/setDisablePrevious',
      doDisableAllSelected: 'admins/list/doDisableAllSelected',
      finishLoading: 'admins/list/finishLoading',
      doToggleStatus: 'iam/view/doToggleStatus'
    }),
    toggleStatus(row){
      this.doToggleStatus(row)
      row.disabled = !row.disabled
    },
    async goToPreviousPage(){
      console.log('go to previous page');
      console.log('prev');
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "prev",
          page: this.page
      }
      await this.doFetch({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy, pagination}) 
      this.page -= 1
      this.finishLoading()
    },
    async loadMore(){
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "next",
          page: this.page
      }
      await this.doFetch({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisablePrevious({companyId: this.currentUser.companyId, orderBy, pagination})
      // await this.setDisableNext({companyId: this.currentUser.companyId, orderBy, pagination}) 
      this.page += 1
      this.finishLoading()
    },
    displayName(value){
      return value ? this.isRTL ? value.name.ar : value.name.en : '___'
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    // presenter function display data from model to fields 
    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      console.log(this.selectedId);
      switch (operation) {
          case 'delete':
          this.dialogType = 'delete'
          break;
          case 'edit':
          this.dialogType = 'edit'
          break;
          case 'view':
          this.dialogType = 'view'
          break;  
          default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
          this.modalVisible = false;
          this.dialogType = undefined
      }, 500);
    },

    onOpenCreateAdminModal() {
      this.createAdminModalVisible = true;
      this.createAdminDialogVisible = true;
    },
    onCreateAdminModalClose() {
      this.createAdminDialogVisible = false;
      setTimeout(() => {
        this.createAdminModalVisible = false;
      }, 200);
    },
    onOpenViewAdminModal() {
      this.viewAdminModalVisible = true;
      this.viewAdminDialogVisible = true;
    },
    onViewAdminModalClose() {
      this.viewAdminDialogVisible = false;
      setTimeout(() => {
        this.viewAdminModalVisible = false;
      }, 200);
    },
    onModalSuccessClose() {
      console.log('CLOSED');
      this.success = false;
      setTimeout(() => {
        this.modalSuccessVisible = false;
      }, 200);
    },
    viewAdmin(row) {
      this.selectedRow=row
      this.onOpenViewAdminModal()
    },
    adminSaved() {
      this.onCreateAdminModalClose();
      this.success = true;
      this.modalSuccessVisible = true;
    },
    deleteAdmin(row){
      this.selectedRow=row
      this.onOpenDeleteAdminModal()
    },
    onOpenDeleteAdminModal() {
      this.deleteAdminModalVisible = true;
      this.deleteAdminDialogVisible = true;
    },
    onDeleteAdminModalClose() {
      this.deleteAdminDialogVisible = false;
      setTimeout(() => {
        this.deleteAdminModalVisible = false;
      }, 200);
    },
    async doDestroyWithConfirm() {      
            try {
                console.log(this.selectedId);
                await this.doDisableAllSelected([this.selectedId])     
                this.onModalClose()  
            } catch (error) {
                // no
            }
        },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
