//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AdminsListTable from '@/modules/admins/components/admins-list-table.vue';
import AdminFormModal from '@/modules/admins/components/admin-form-modal.vue';

// import { i18n } from '@/i18n';

export default {
  name: 'app-admins-list-page',

  components: {
    [AdminsListTable.name]: AdminsListTable,
    [AdminFormModal.name]: AdminFormModal
  },

  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      admins: [
          {
              id: "1",
              fullName: 'Ahmed Refaat',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },{
              id: "2",
              fullName: 'Ahmed',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },{
              id: "3",
              fullName: 'Ahmed Refaat',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },{
              id: "4",
              fullName: 'Ahmed Refaat',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },{
              id: "5",
              fullName: 'Ahmed Refaat',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },{
              id: "6",
              fullName: 'Ahmed Refaat',
              phoneNumber: '+966123456',
              emailAddress: 'ahmed@gmail.com',
              branch: 'Makkah',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              createdAt: '22-8-2018',
              updatedAt: '22-8-2018',
              role: 'Viewer',
              branchName: 'Branch Name'
          },

      ],
      success: false,
      modalSuccessVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // hasPermissionToCreate() {
    //   return new BranchPermissions(this.currentUser).create;
    // },
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      console.log('CLOSED');
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    onModalSuccessClose() {
      console.log('CLOSED');
      this.success = false;
      setTimeout(() => {
        this.modalSuccessVisible = false;
      }, 200);
    },
    adminSaved() {
      this.onModalClose();
      this.success = true;
      this.modalSuccessVisible = true;
    },
  },
};
